<template>
  <a-select v-bind="$attrs" :options="options" placeholder="请选择" />
</template>

<script setup name="AppCurrencySelect">
  import { useSiteStore } from '@admin/store/modules/site'
  const props = defineProps({
    values: {
      type: [String, Array],
      default: () => [],
    },
  })

  const siteStore = useSiteStore()

  const options = computed(() => {
    const filter = { values: props.values }
    return siteStore.filterCurrencies(filter)
  })
</script>
