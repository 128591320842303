<template>
  <!-- <a-col v-bind="actionColOpt" v-if="showActionButtonGroup"> -->
  <div
    :style="{ float: actionColOpt.style.textAlign }"
    :class="{ 'ml-2': actionColOpt.style.textAlign == 'left' }"
    v-if="showActionButtonGroup"
  >
    <!-- <div style="width: 100%" :style="{ textAlign: actionColOpt.style.textAlign }"> -->
    <FormItem>
      <slot name="resetBefore"></slot>

      <span class="mr-2">
        <a-button
          type="primary"
          v-bind="getSubmitBtnOptions"
          @click="submitAction()"
          v-if="showSubmitButton"
        >
          {{ getSubmitBtnOptions.text }}
        </a-button>
      </span>

      <span>
        <a-button
          type="default"
          v-bind="getResetBtnOptions"
          @click="resetAction"
          v-if="showResetButton"
        >
          {{ getResetBtnOptions.text }}
        </a-button>
      </span>
      <slot name="submitBefore"></slot>

      <slot name="advanceBefore"></slot>
      <a-button
        type="link"
        size="small"
        @click="toggleAdvanced"
        v-if="showAdvancedButton && !hideAdvanceBtn"
      >
        {{ isAdvanced ? t('component.form.putAway') : t('component.form.unfold') }}
        <BasicArrow class="ml-1" :expand="!isAdvanced" up />
      </a-button>
      <slot name="advanceAfter"></slot>
    </FormItem>
  </div>
  <!-- </a-col> -->
</template>
<script lang="ts">
  import type { ColEx } from '../types/index'
  //import type { BasicButtonProps } from 'ant-design-vue/es/button/buttonTypes';
  import { defineComponent, computed, PropType } from 'vue'
  import { Form, Col } from 'ant-design-vue'
  import { ButtonProps } from 'framework/components/Button'
  import { BasicArrow } from 'framework/components/Basic'
  import { useFormContext } from '../hooks/useFormContext'
  import { useI18n } from 'framework/hooks/web/useI18n'
  import { propTypes } from 'framework/utils/propTypes'

  type ButtonOptions = Partial<ButtonProps> & { text: string }

  export default defineComponent({
    name: 'BasicFormAction',
    components: {
      FormItem: Form.Item,
      BasicArrow,
      [Col.name]: Col,
    },
    props: {
      showActionButtonGroup: propTypes.bool.def(true),
      showResetButton: propTypes.bool.def(true),
      showSubmitButton: propTypes.bool.def(true),
      showAdvancedButton: propTypes.bool.def(true),
      resetButtonOptions: {
        type: Object as PropType<ButtonOptions>,
        default: () => ({}),
      },
      submitButtonOptions: {
        type: Object as PropType<ButtonOptions>,
        default: () => ({}),
      },
      actionColOptions: {
        type: Object as PropType<Partial<ColEx>>,
        default: () => ({}),
      },
      actionSpan: propTypes.number.def(6),
      isAdvanced: propTypes.bool,
      hideAdvanceBtn: propTypes.bool,
    },
    emits: ['toggle-advanced'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const actionColOpt = computed(() => {
        const { showAdvancedButton, actionSpan: span, actionColOptions } = props
        const actionSpan = 24 - span
        const advancedSpanObj = showAdvancedButton ? { span: actionSpan < 6 ? 24 : actionSpan } : {}
        const actionColOpt: Partial<ColEx> = {
          style: { textAlign: 'right' },
          span: showAdvancedButton ? 6 : 4,
          ...advancedSpanObj,
          ...actionColOptions,
        }
        return actionColOpt
      })
      const getResetBtnOptions = computed((): ButtonOptions => {
        return Object.assign(
          {
            text: t('common.resetText'),
          },
          props.resetButtonOptions,
        )
      })
      const getSubmitBtnOptions = computed(() => {
        return Object.assign(
          {
            text: t('common.searchText'),
          },
          props.submitButtonOptions,
        )
      })

      function toggleAdvanced() {
        emit('toggle-advanced')
      }

      return {
        t,
        actionColOpt,
        getResetBtnOptions,
        getSubmitBtnOptions,
        toggleAdvanced,
        ...useFormContext(),
      }
    },
  })
</script>
