import { defineStore } from 'pinia'
import { store } from '@admin/store'
import { fetchShopConfig, fetchCurrencyOptions } from '@admin/api/cos/site'

export const useSiteStore = defineStore('app-site', () => {
  // 全部站点
  const sites = ref<any>([])
  // 全部店铺：货币转换下拉枚举
  const currencies = ref<any>([])

  // 过滤站点
  const filterSites = computed(() => {
    return ({ status, channel = [] }: any = {}) => {
      return sites.value
        .filter((site) => (status === undefined ? true : site.status === status))
        .filter((site) => (channel.length === 0 ? true : channel.includes(site.channel)))
    }
  })

  //过滤货币
  const filterCurrencies = computed(() => {
    return ({ values }: any = {}) => {
      return currencies.value.filter((site) =>
        values.length === 0 ? true : values.includes(site.value),
      )
    }
  })

  //获取站点
  const getSites = async () => {
    const { data } = await fetchShopConfig()
    sites.value = data
  }

  //获取货币
  const getCurrencyOptions = async () => {
    const { data } = await fetchCurrencyOptions()
    currencies.value = data
  }

  const getSiteByCode = (siteCode) => sites.value.find((site) => site.value === siteCode)

  return {
    sites,
    currencies,
    filterSites,
    filterCurrencies,
    getSites,
    getCurrencyOptions,
    getSiteByCode,
  }
})

// Need to be used outside the setup
export function useSiteStoreWithOut() {
  return useSiteStore(store)
}
