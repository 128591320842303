import { adminRequest } from '@admin/utils/http/axios'

/*
  店铺站点下拉枚举
  GET /cos/shop_config/options
  接口地址：https://app.apifox.com/link/project/1312103/apis/api-239497878
*/
export const fetchShopConfig = (params = {}): Promise<any> => {
  return adminRequest.get({
    url: '/cos/shop_config/options',
    params,
  })
}

/*
  店铺：货币转换下拉枚举
  GET /cos/shop_config/currency_conversion_options
  接口地址：https://app.apifox.com/project/1312103/apis/api-253537316
*/
export const fetchCurrencyOptions = (params = {}): Promise<any> => {
  return adminRequest.get({
    url: '/cos/shop_config/currency_conversion_options',
    params,
  })
}
