// import 'virtual:windi-base.css'
// import 'virtual:windi-components.css'
// import 'virtual:windi-utilities.css'
// import 'tailwindcss/tailwind.css'

import 'virtual:svg-icons-register'
import '@admin/design/index.less'
import App from './App.vue'
import { createApp } from 'vue'
import { initAppConfigStore } from '@admin/logics/initAppConfig'
import { router, setupRouter } from '@admin/router'
import { setupRouterGuard } from '@admin/router/guard'
import { setupStore } from '@admin/store'
import { setupGlobDirectives } from '@admin/directives'
import { setupI18n } from 'framework/locales/setupI18n'
import { registerGlobAntdComp } from 'framework/components/registerGlobAntdComp'
import { registerGlobComponent } from 'framework/components/registerGlobComponent'
import { setupErrorHandle } from '@admin/logics/error-handle'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import setupMonitor from './logics/monitor'
import { AppSiteSelect, AppSiteTabs, AppCurrencySelect } from '@admin/components/Site'

dayjs.extend(weekday)
dayjs.extend(localeData)

//监控平台
// import * as Sentry from '@sentry/vue'
// Sentry.init({
//   dsn: 'https://506c6b6787082d403a1fa364306c8c63@o4506573821640704.ingest.sentry.io/4506573823410176',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/myqcloud\//],
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

async function bootstrap() {
  const app = createApp(App)

  // 注册全局antd组件
  registerGlobAntdComp(app)

  // 注册全局项目组件
  registerGlobComponent(app)

  // 安装表格
  // Configure store
  // 配置 store
  setupStore(app)
  // Initialize internal system configuration
  // 初始化内部系统配置
  initAppConfigStore()

  // Multilingual configuration
  // 多语言配置
  // Asynchronous case: language files may be obtained from the server side
  // 异步案例：语言文件可能从服务器端获取
  await setupI18n(app)

  // Configure routing
  // 配置路由
  setupRouter(app)

  // router-guard
  // 路由守卫
  setupRouterGuard(router)

  // Register global directive
  // 注册全局指令
  setupGlobDirectives(app)
  // 配置全局错误处理
  setupErrorHandle(app)
  // https://next.router.vuejs.org/api/#isready
  // await router.isReady();
  // () => {
  //   registerTableInputEvent()
  // }

  // 启动监控
  setupMonitor()

  // 注册内部全局组件
  app.use(AppSiteTabs)
  app.use(AppSiteSelect)
  app.use(AppCurrencySelect)

  app.mount('#app').$nextTick()
}

bootstrap()
