import { getLastPartAfterSlash } from './index'
/**
 * 下载
 * @param url
 * @param name
 */
function download(url: string, name?: string) {
  console.log('download', url, name)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.download = name || ''
  if (url.includes('http:')) url = url.replace('http:', 'https:')
  link.target = '_blank'
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

//兼容图片链接的下载，转成base64
export const downLoadImageAndFile = (item) => {
  if (item.indexOf('jpg') > -1 || item.indexOf('png') > -1 || item.indexOf('jpeg') > -1) {
    const imageUrl = item
    const img = new Image()
    img.src = imageUrl
    img.crossOrigin = 'anonymous'
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const context: any = canvas.getContext('2d')
      context.drawImage(img, 0, 0, img.width, img.height)
      const base64Data = canvas.toDataURL(`image/${item.split('.')[1]}`)
      download(base64Data, getLastPartAfterSlash(item))
    }
  } else {
    download(item)
  }
}

export const downLoadFile = (res, name, documentType = '.xlsx') => {
  console.log('res, name', res, name)
  const aLink = document.createElement('a')
  const blob = new Blob([res], {
    type: 'application/vnd.ms-excel',
  })
  // 创建一个当前文件的内存URL
  const _href = URL.createObjectURL(blob)
  aLink.style.display = 'none'
  aLink.href = _href
  document.body.appendChild(aLink)
  if (name && name.indexOf('.') > -1) {
    aLink.download = name
  } else {
    aLink.download = name + documentType
  }

  aLink.click()
  document.body.removeChild(aLink)
  // 手动释放创建的URL对象所占内存
  URL.revokeObjectURL(_href)
}
export function isFileStream(data) {
  // 确保 data 是 Buffer 或字符串
  if (typeof data === 'string') {
    return data.startsWith('PK')
  } else if (data instanceof Uint8Array || Buffer.isBuffer(data)) {
    return data[0] === 0x50 && data[1] === 0x4b // "PK" 的 ASCII 编码
  }
  return false
}

export function isFileStreamFromHeaders(headers) {
  const contentType = headers['content-type'] || ''
  // 常见文件流 MIME 类型，例如 ZIP 文件
  return contentType.includes('application/zip') || contentType.includes('application/octet-stream')
}

export default download
